import React, { useState, useEffect } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './App.css';
import firebase from './Utils/firebase'
import BookApp from './Widgets/BookApp';
import Subscribe from './Public/Subscribe';
import Main from './Public/Main';
import Loading from './Components/Loading';
import { STRIPE_ROLES } from './Utils/Constants';
import { BrowserRouter } from "react-router-dom";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#BB2025',
        },
        secondary: {
            main: '#38121F',
        },
        error: {
            main: '#B00020',
        },
        warning: {
            main: '#FFCC00',
        },
        info: {
            main: '#188DED',
        },
        success: {
            main: '#4BB543',
        },
        
    },
    spacing: 4
});

function SignedInCheck(props) {
  //user will be undefined when loading
  //user will be null when logged out
  if (props.user === undefined) {
    return <Loading />;
  }
  if (props.user) {
    return <SubscribedCheck />;
  }
  return <Main />;
}

function SubscribedCheck() {
    const [view, setView] = useState(<Loading />);

    useEffect(() => {
        async function getCustomClaimRole() {
            await firebase.auth().currentUser.getIdToken(true);
            await firebase.auth().currentUser.getIdTokenResult().then((decodedToken) => {
                if (decodedToken.claims.stripeRole === STRIPE_ROLES.PAYING_USER) {
                    setView(<BookApp></BookApp>);
                } else {
                    setView(<Subscribe></Subscribe>);
                }
            });
        }
        getCustomClaimRole();
    }, []);
        
  return (
    view
  );
}

function App() {
  const [user, setUser] = useState();

  firebase.auth().onAuthStateChanged((thisUser) => {
    setUser(thisUser);
  });

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SignedInCheck user={user}></SignedInCheck>
        </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;