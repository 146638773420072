import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/firebase-storage";
import "firebase/functions";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDtFWpfXefnB04h5nDWngTTMzKbzwHDOSg",
  authDomain: "book-8b2b5.firebaseapp.com",
  databaseURL: "https://book-8b2b5.firebaseio.com",
  projectId: "book-8b2b5",
  storageBucket: "book-8b2b5.appspot.com",
  messagingSenderId: "607149085547",
  appId: "1:607149085547:web:9d245fa10bb416f9f5722e",
  measurementId: "G-LCNGXWBF9Q"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const db = firebase.firestore();
export const auth = firebase.auth();

export default firebase;
