import React from 'react';
import {
  Switch,
  Route,
} from "react-router-dom";
import SignIn from './SignIn';
import SignUp from './SignUp';
import HowToFold from './HowToFold';
import LandingPage from './LandingPage';
import { makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { BRAND_COLORS } from '../Utils/Constants';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    [theme.breakpoints.up("xs")]: {
      minHeight: "56px",
    },
  },
  flex: {
    flexGrow: 1,
  },
}));

const Main = () => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const classes = useStyles();
    const toggleDrawer = (open) => (event) => {
        if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
        ) {
        return;
        }
        setDrawerOpen(open);
    };
    return (<>         
        
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>
          <ListItem
            key={"Lotus"}
            style={{minHeight: "185px"}}
            onClick={() => {
              setDrawerOpen(false);
              window.location.href = "/";
            }}
          >
            <img src={process.env.PUBLIC_URL + '/LogoTransparent.png'} alt="Lotus Book Folding" width={200} />
          </ListItem>
          <Divider />
          <ListItem
            button
            key={"SignUp"}
            onClick={() => {
              setDrawerOpen(false);
              window.location.href = "/signup";
            }}
          >
            <Button variant="contained" color="primary" style={{width:"100%", backgroundColor: BRAND_COLORS.SUCCESS, color: BRAND_COLORS.WHITE}}>
                Start Folding
            </Button>
          </ListItem>
          <ListItem
            button
            key={"HowTo"}
            onClick={() => {
              setDrawerOpen(false);
              window.location.href = "/Howtofold";
            }}
          >
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText primary="How to fold" />
          </ListItem>
          <ListItem
            button
            key={"Bug"}
            onClick={() => {
              setDrawerOpen(false);
              window.location.href = "/signin";
            }}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Sign in" />
          </ListItem>
        </List>
      </Drawer>
      <AppBar position="fixed" color="secondary">
        <Toolbar className={classes.toolbar} style={{display: "flex"}}>
          <div style={{ display: "flex", flex: 1, marginRight: "auto", justifyContent: "flex-start" }}></div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={process.env.PUBLIC_URL + '/IconWhiteTransparent.png'} alt="Lotus Book Folding" height={56} width={56} onClick={() => {
              setDrawerOpen(false);
              window.location.href = "/";
            }}></img>
          </div>
          <div style={{ display: "flex", flex: 1, marginLeft: "auto", justifyContent: "flex-end" }}>
            <IconButton
            edge="start"
            color="inherit"
            aria-label="Menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton> 
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar className={classes.toolbar} />
        <Switch>
            <Route path="/signin">
                <SignIn showSignIn/>
            </Route>
            <Route path="/signup">
                <SignUp/>
            </Route>
            <Route path="/howtofold">
                <HowToFold/>
            </Route>
            <Route path="/">
                <LandingPage/>
          </Route>
        </Switch>
    </>)
}

export default Main;