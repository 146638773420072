import React from "react";
import {
  Switch,
  Route,
} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import BugReportIcon from "@material-ui/icons/BugReport";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

import Bookshelf from "./BookShelf";
import Account from "./Account";
import ReportBug from "./ReportBug";
import Book from "./Book";
import firebase from "../Utils/firebase";

import Test from "./Test";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    [theme.breakpoints.up("xs")]: {
      minHeight: "56px",
    },
  },
  flex: {
    flexGrow: 1,
  },
}));

function BookApp() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const classes = useStyles();
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <>
      <Drawer open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>
          <ListItem
            key={"Lotus"}
            style={{minHeight: "185px"}}
          >
            <img src={process.env.PUBLIC_URL + '/LogoTransparent.png'} alt="Lotus Book Folding" width={200} />
          </ListItem>
          <Divider />
          <ListItem
            button
            key={"Bookshelf"}
            onClick={() => {
              setDrawerOpen(false);
              window.location.href = "/";
            }}
          >
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText primary="Bookshelf" />
          </ListItem>
          <ListItem
            button
            key={"Account"}
            onClick={() => {
              setDrawerOpen(false);
              window.location.href = "/Account";
            }}
          >
            <ListItemIcon>
              <AccountBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Account" />
          </ListItem>
          <ListItem
            button
            key={"Bug"}
            onClick={() => {
              setDrawerOpen(false);
              window.location.href = "/featurerequest";
            }}>
            <ListItemIcon>
              <BugReportIcon />
            </ListItemIcon>
            <ListItemText primary="Feature request" />
          </ListItem>
          <ListItem
            button
            onClick={() => {
              window.location.href = "/";
              firebase.auth().signOut();
            }}
          >
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Sign out" />
          </ListItem>
        </List>
      </Drawer>
      <AppBar position="fixed" color="secondary">
        <Toolbar className={classes.toolbar} style={{display: "flex"}}>
          <div style={{ display: "flex", flex: 1, marginRight: "auto", justifyContent: "flex-start" }}>
            <IconButton
            edge="start"
            color="inherit"
            aria-label="Menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={process.env.PUBLIC_URL + '/IconWhiteTransparent.png'} alt="Lotus Book Folding" height={56} width={56} />
          </div>
          <div style={{ display: "flex", flex: 1, marginLeft: "auto", justifyContent: "flex-end" }}>
            <Button
            variant="contained"
            color="primary"
            onClick={() => {
              window.location.href = "/book";
            }}
          >
            Create Book
          </Button> 
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar style={{ marginBottom: "20px" }} className={classes.toolbar} />

      <Container>
        <Switch>
          <Route path="/book">
            <Book />
          </Route>
          <Route path="/featurerequest">
            <ReportBug />
          </Route>
          <Route path="/account">
            <Account />
          </Route>
          <Route path="/test">
            <Test />
          </Route>
          <Route path="/">
            <Bookshelf />
          </Route>
        </Switch>
      </Container>
    </>
  );
}

export default BookApp;
