import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import Link from '@material-ui/core/Link';
import Typography from "@material-ui/core/Typography";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { BRAND_COLORS } from '../Utils/Constants';
import CustomizedSnackbar from "../Components/Snackbar";

import firebase from '../Utils/firebase';
import { EVENT, logEvent } from '../Utils/Utils'

function SignInForm() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [disableSignInButton, setDisableSignInButton] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [disableSendButton, setDisableSendButton] = useState(false);
    const [snackbar, setSnackbar] = useState(null);
    const [resetEmail, setResetEmail] = useState('');

    document.body.style = "background: " + BRAND_COLORS.PRIMARY;

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setResetEmail(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleResetEmailChange = (event) => {
        setResetEmail(event.target.value);
    }

    const sendPasswordReset = () => {
        setDisableSendButton(true);
        firebase.auth().sendPasswordResetEmail(resetEmail).then(function () {
            setDialogOpen(false);
            setSnackbar({
                text: "Password reset email sent",
                severity: "success",
            });
        }).catch(function (error) {
            logEvent(EVENT.CATCH, "sendPasswordReset", error.message, error.code);
            setSnackbar({
                text: "Could not send reset email",
                severity: "error",
            });
        }).finally(() => {
            setDisableSendButton(false);
        });
    }

    const signIn = () => {
        setDisableSignInButton(true);
        firebase.auth().signInWithEmailAndPassword(email, password)
            .then(() => {
                window.location.href = "/";
            })
            .catch(function (error) {
            logEvent(EVENT.CATCH, "signIn", error.message, error.code);
            setSnackbar({
                text: error.message,
                severity: "error",
            });
        }).finally(() => {
            setDisableSignInButton(false);
        });
    }

    const handleKeypress = (e) => {
        if (e.charCode === 13) {
            signIn();
        }
    };
    
    return (
        <>
            <Grid container justify="center" alignItems="center">
                <Grid item xs={10} sm={6} md={4} lg={3} style={{marginTop:"30px"}}>
                    <Paper>
                        <Grid container
                            direction="column"
                            justify="space-around"
                            alignItems="center"
                            spacing={4}
                            style={{paddingBottom: 20}}
                        >
                            <Grid item container justify="center"><img src={process.env.PUBLIC_URL + '/LogoTransparent.png'} alt="Lotus Book Folding" height={250} width={250} style={{height:"70%", width:"70%"}} /></Grid>
                            <Grid item><Typography variant="h4" color="secondary">Sign In</Typography></Grid>
                            <Grid item><TextField label="Email" type="email" value={email} onChange={handleEmailChange} onKeyPress={handleKeypress} color="secondary"/></Grid>
                            <Grid item><TextField label="Password" type="password" value={password} onChange={handlePasswordChange} onKeyPress={handleKeypress}  color="secondary"/></Grid>
                            <Grid item><Link onClick={handleDialogOpen}>Forgot your password?</Link></Grid>
                            <Grid item><Button variant="contained" color="secondary" onClick={signIn} disabled={disableSignInButton}>Sign in</Button></Grid>
                            <Grid item><Typography variant="h5"></Typography></Grid>
                            <Grid item><Button variant="outlined" color="secondary" onClick={() => { window.location.href = "/signup";}}>Don't have an account?</Button></Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <Dialog open={dialogOpen} onClose={handleDialogClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Password Reset</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Enter the email address that you used to register. We'll send you an email with a link to reset your password.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Email Address"
                    type="email"
                    value={resetEmail}
                    onChange={handleResetEmailChange}
                    color="secondary"
                    fullWidth
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleDialogClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={sendPasswordReset} style={{backgroundColor: BRAND_COLORS.SUCCESS, color: BRAND_COLORS.WHITE}} disabled={disableSendButton}>
                    Send
                </Button>
                </DialogActions>
            </Dialog>
            {snackbar && (
                <CustomizedSnackbar
                callback={() => {
                    setSnackbar(null);
                }}
                {...snackbar}
                />
            )}
        </>
    );
}

export default SignInForm;