import { useLocation } from "react-router-dom";
import firebase from "./firebase";

const namePrefix = "bk-";
const nameLength = 32;

export function getRandomName() {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < nameLength; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return namePrefix + result;
}

export function getImageNameFromURL(url) {
  let startIndex = url.indexOf(namePrefix);
  let name = url.substring(startIndex);
  let endIndex = name.indexOf(".");

  name = name.substring(0, endIndex);

  return name;
}

export function getImageNameWithExtensionFromURL(url) {
  let startIndex = url.indexOf(namePrefix);
  let name = url.substring(startIndex);
  let endIndex = name.indexOf("?");

  //If there is a question mark, take it and everything afterwards out
  if (endIndex > 0) {
    name = name.substring(0, endIndex);
  }

  return name;
}

export function round(value, precision) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

export function getHeightCM(heightMM) {
  let heightCM = heightMM / 10;
  return String(parseFloat(heightCM.toFixed(1))).replace(/^0+/, '');
}

export const EVENT = {
  CATCH: "catch"
};

export function logEvent(name, method, message, meta) {
  let uid = null;
  
  if (firebase.auth() && firebase.auth().currentUser) {
    uid = firebase.auth().currentUser.uid;
  }

  firebase.analytics().logEvent(name, {"method": method, "message": message, "meta": meta, "uid": uid});
}

export function getCutMeasurement(folds, foldIndex) {
  let measurement = 0;
  for (var i = 0; i <= foldIndex; i++) {
    measurement += folds[i].length;
  }
  return measurement;
}

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}