import React from "react";
import Jimp from "jimp";
import { THRESHOLD } from "../Utils/Constants";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";
import Button from "@material-ui/core/Button";
import { EVENT, logEvent } from '../Utils/Utils'

class FileInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.fileInput = React.createRef();
  }

  handleChange(event) {
    event.preventDefault();
    const callback = this.props.callBack;
    var reader = new FileReader();
    if (!typeof this.fileInput.current.files[0] === 'Blob'){
      return;
    }
    reader.readAsDataURL(this.fileInput.current.files[0]);

    reader.onload = function (e) {
      Jimp.read(e.target.result)
        .then((image) => {
          image.threshold({ max: THRESHOLD });
          image.getBase64(Jimp.AUTO, (err, src) => {
            callback(src);
          });
        })
        .catch((error) => {
          logEvent(EVENT.CATCH, "handleChange", error, "FileInput.js")
        });
    };
  }

  render() {
    return (
      <>
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="contained-button-file"
          type="file"
          ref={this.fileInput}
          onChange={this.handleChange}
        />
        <label htmlFor="contained-button-file">
          <Button
            component="span"
            variant="outlined"
            size="medium"
            color="primary"
            startIcon={<CropOriginalIcon />}
          >
            Browse
          </Button>
        </label>
      </>

      //<input type="file" ref={this.fileInput} onChange={this.handleChange} />
    );
  }
}

export default FileInput;
