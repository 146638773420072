import React, { useState } from "react";
import firebase from "../Utils/firebase";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import CustomizedSnackbar from "../Components/Snackbar";

const Account = () => {
    const [snackbar, setSnackbar] = useState(null);
    const [subscriptionLoading, setSubscriptionLoading] = React.useState(false);
    const [resetLoading, setResetLoading] = React.useState(false);

    const showStripeCustomerPortal = async () => {
        setSubscriptionLoading(true);
        setSnackbar({
          text: "Redirecting you to payment",
          severity: "success",
        });
        const functionRef = firebase
        .app()
        .functions('us-central1')
        .httpsCallable('ext-firestore-stripe-subscriptions-createPortalLink');
        const { data } = await functionRef({ returnUrl: window.location.origin });
        if (data.url) {
            window.location.assign(data.url);
        } else {
            setSubscriptionLoading(false);
        }
    }

    const sendPasswordResetEmail = () => {
        setResetLoading(true)
        var auth = firebase.auth();
        var emailAddress = auth.currentUser.email;

        auth.sendPasswordResetEmail(emailAddress).then(function() {
            setSnackbar({
                text: "Reset password email sent",
                severity: "success",
            });
        }).catch(function(error) {
            setSnackbar({
                text: "Error sending email",
                severity: "error",
            });
        }).finally(() => {
            setResetLoading(false);
        });
    }

    return (
        <>
            {snackbar && (
                <CustomizedSnackbar
                callback={() => {
                    setSnackbar(null);
                }}
                {...snackbar}
                />
            )}
            <Grid container spacing={4}>
                <Grid item xs={12}>
                <Typography variant="h2" color="secondary">
                    Update Account
                </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        variant="outlined"
                        size="medium"
                        color="primary"
                        disabled={subscriptionLoading}
                        onClick={() => { showStripeCustomerPortal()}}
                    >
                        Manage Subscription
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        variant="outlined"
                        size="medium"
                        color="primary"
                        disabled={resetLoading}
                        onClick={() => { sendPasswordResetEmail()}}
                    >
                        Reset Password
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default Account;