import Jimp from "jimp";
import {
  THRESHOLD,
  JIMP_COLORS,
  DEFAULT_DEPTH,
  FOLD_TYPES,
  IMAGE_TYPES,
} from "../Utils/Constants";
import { EVENT, logEvent } from '../Utils/Utils'

//For MMF patters, the black part is unfolded. For all other fold types, black means folding in.
const getDepth = (color, foldType, imageType) => {
  if (imageType === IMAGE_TYPES.TEXT) {
    return color === JIMP_COLORS.BLACK ? 0 : DEFAULT_DEPTH;
  } else if (foldType === FOLD_TYPES.MMF) {
    return color === JIMP_COLORS.BLACK ? 0 : DEFAULT_DEPTH;
  } else {
    return color === JIMP_COLORS.BLACK ? DEFAULT_DEPTH : 0;
  }
}

const GeneratePattern = (bookAttributes) => {
  return new Promise((resolve, reject) => {
    const {
      resizedImageURL,
      foldType,
      height,
      lastOddPage,
      imageType,
    } = bookAttributes;

    const pages = (lastOddPage + 1) / 2;

    if (!resizedImageURL) {
      return;
    }

    Jimp.read(resizedImageURL)
      .then((image) => {
        let initialPattern = {
          pages: image.bitmap.width,
          height: image.bitmap.height,
          Pages: [],
        };

        //If these dont match, the image hasn't been re-sized before generating the image. 
        //Just return as we will re-render this again after it is resized.
        if (initialPattern.pages !== pages || initialPattern.height !== height) {
          return;
        }

        //Generate initial pattern from image
        for (var x = 0; x < image.bitmap.width; x++) {
          let page = {};
          let folds = [];
          let currentFold;

          let previousColor;
          for (var y = 0; y < image.bitmap.height; y++) {
            let thisColor;

            if (imageType === IMAGE_TYPES.TEXT) {
              if (Jimp.intToRGBA(image.getPixelColor(x, y)).r < THRESHOLD) {
                thisColor = JIMP_COLORS.WHITE;
              } else {
                thisColor = JIMP_COLORS.BLACK;
              }
            } else {
              if (Jimp.intToRGBA(image.getPixelColor(x, y)).r < THRESHOLD) {
                //Black
                thisColor = JIMP_COLORS.BLACK;
              } else {
                //White
                thisColor = JIMP_COLORS.WHITE;
              }
            }

            
            if (y === 0) {
              //**First fold**
              currentFold = {
                start: 0,
                end: 0,
                length: 0,
                depth: getDepth(thisColor, foldType, imageType),
                color: thisColor,
              };
              currentFold.length++;
            } else {
              const isSameColor = thisColor === previousColor;

              if (isSameColor) {
                currentFold.length++;
              } else {
                //New fold
                //Push previous fold
                currentFold.end = y;
                folds.push(currentFold);

                //Create New fold
                currentFold = {
                  start: y,
                  end: 0,
                  length: 0,
                  depth: getDepth(thisColor, foldType, imageType),
                  color: thisColor,
                };
                currentFold.length++;
              }
            }
            //Last fold
            if (y === image.bitmap.height - 1) {
              currentFold.end = image.bitmap.height;
              folds.push(currentFold);
            }
            previousColor = thisColor;
          }

          //Add folds to page
          page.Folds = folds;
          //Add number to page
          if (x === image.bitmap.width - 1) {
            page.number = lastOddPage;
          } else {
            const difference = image.bitmap.width - 1 - x;
            page.number = lastOddPage - (difference * 2);
          }
          //Add page to pattern
          initialPattern.Pages.push(page);
        }

        //Post-Pattern changes
        if (foldType === FOLD_TYPES.MMCF || foldType === FOLD_TYPES.COMBI) {
          resolve(initialPattern);
        } else if (foldType === FOLD_TYPES.SHADOW) {
          for (var i = 0; i < pages; i++) {
            if (initialPattern.Pages[i].Folds.length > 1 && i % 2) {
              const unfoldedPage = {
                start: 0,
                end: height,
                length: height,
                depth: 0,
                color: JIMP_COLORS.WHITE,
              };
              initialPattern.Pages[i].Folds = [unfoldedPage];
            }
          }
          resolve(initialPattern);
        } else if (foldType === FOLD_TYPES.MMF) {
          let MMF = {
            pages: initialPattern.pages,
            height: initialPattern.height,
            Pages: [],
          };

          let currentSameDepthArray = [];

          //Go through each page
          for (var p = 0; p < pages; p++) {
            //Set this pages depthFoldCount
            let page = initialPattern.Pages[p];
            let filter = page.Folds.filter((a) => a.depth === 0);
            let depthFoldCount = filter.length;
            initialPattern.Pages[p].depthFoldCount = depthFoldCount;

            //Check to see if we need to do anything with this page
            if (initialPattern.Pages[p].depthFoldCount > 1) {
              //We need to do something with this page

              if (p === 0) {
                currentSameDepthArray.push(initialPattern.Pages[p]);
                continue;
              }

              //is this sameDepthCount the same as the previous page?
              const thisDepthFoldCount = initialPattern.Pages[p].depthFoldCount;
              const previousDepthFoldCount =
                initialPattern.Pages[p - 1].depthFoldCount;
              if (thisDepthFoldCount === previousDepthFoldCount) {
                //push it to the currentSameDepthArray for further processing
                currentSameDepthArray.push(initialPattern.Pages[p]);
              } else {
                //were on to a different set of same depths.

                //process currentSameDepthArray
                let foldIndex = 0;
                for (let sd = 0; sd < currentSameDepthArray.length; sd++) {
                  //change the page to only have one depth
                  let thisPage = currentSameDepthArray[sd];
                  let newFoldArray = [];

                  //find fold we want to keep
                  let chosenFold = thisPage.Folds.filter((a) => a.depth === 0)[
                    foldIndex
                  ];
                  let chosenFoldIndex = thisPage.Folds.map(function (e) {
                    return e.start;
                  }).indexOf(chosenFold.start);

                  if (chosenFoldIndex === 0) {
                    //If fold is the first, add another fold after it
                    newFoldArray.push(chosenFold);
                    const nextFold = {
                      start: chosenFold.end,
                      end: initialPattern.height,
                      length: initialPattern.height - chosenFold.end,
                      depth: DEFAULT_DEPTH,
                      color: JIMP_COLORS.WHITE,
                    };
                    newFoldArray.push(nextFold);
                  } else if (chosenFoldIndex === thisPage.Folds.length - 1) {
                    //If fold is the last, add another fold before it
                    const previousFold = {
                      start: 0,
                      end: chosenFold.start,
                      length: chosenFold.start,
                      depth: DEFAULT_DEPTH,
                      color: JIMP_COLORS.WHITE,
                    };
                    newFoldArray.push(previousFold);
                    newFoldArray.push(chosenFold);
                  } else {
                    //If fold is in the middle, add a fold on either side of it
                    const previousFold = {
                      start: 0,
                      end: chosenFold.start,
                      length: chosenFold.start,
                      depth: DEFAULT_DEPTH,
                      color: JIMP_COLORS.WHITE,
                    };
                    newFoldArray.push(previousFold);
                    newFoldArray.push(chosenFold);
                    const nextFold = {
                      start: chosenFold.end,
                      end: initialPattern.height,
                      length: initialPattern.height - chosenFold.end,
                      depth: DEFAULT_DEPTH,
                      color: JIMP_COLORS.WHITE,
                    };
                    newFoldArray.push(nextFold);
                  }

                  thisPage.Folds = newFoldArray;
                  MMF.Pages.push(thisPage);

                  if (
                    foldIndex ===
                    currentSameDepthArray[0].depthFoldCount - 1
                  ) {
                    foldIndex = 0;
                  } else {
                    foldIndex++;
                  }
                }

                //empty currentSameDepthArray
                currentSameDepthArray = [];
                //and push this page to the array.
                currentSameDepthArray.push(initialPattern.Pages[p]);
              }
            } else {
              //check if we need to process the currentSameDepthArray first
              if (currentSameDepthArray.length > 0) {
                //process currentSameDepthArray
                let foldIndex = 0;
                for (var sd = 0; sd < currentSameDepthArray.length; sd++) {
                  //change the page to only have one depth
                  let thisPage = currentSameDepthArray[sd];
                  let newFoldArray = [];

                  //find fold we want to keep
                  let chosenFold = thisPage.Folds.filter((a) => a.depth === 0)[
                    foldIndex
                  ];
                  let chosenFoldIndex = thisPage.Folds.map(function (e) {
                    return e.start;
                  }).indexOf(chosenFold.start);

                  if (chosenFoldIndex === 0) {
                    //If fold is the first, add another fold after it
                    newFoldArray.push(chosenFold);
                    const nextFold = {
                      start: chosenFold.end,
                      end: initialPattern.height,
                      length: initialPattern.height - chosenFold.end,
                      depth: 0,
                      color: JIMP_COLORS.WHITE,
                    };
                    newFoldArray.push(nextFold);
                  } else if (chosenFoldIndex === thisPage.Folds.length - 1) {
                    //If fold is the last, add another fold before it
                    const previousFold = {
                      start: 0,
                      end: chosenFold.start,
                      length: chosenFold.start,
                      depth: 0,
                      color: JIMP_COLORS.WHITE,
                    };
                    newFoldArray.push(previousFold);
                    newFoldArray.push(chosenFold);
                  } else {
                    //If fold is in the middle, add a fold on either side of it
                    const previousFold = {
                      start: 0,
                      end: chosenFold.start,
                      length: chosenFold.start,
                      depth: 0,
                      color: JIMP_COLORS.WHITE,
                    };
                    newFoldArray.push(previousFold);
                    newFoldArray.push(chosenFold);
                    const nextFold = {
                      start: chosenFold.end,
                      end: initialPattern.height,
                      length: initialPattern.height - chosenFold.end,
                      depth: 0,
                      color: JIMP_COLORS.WHITE,
                    };
                    newFoldArray.push(nextFold);
                  }

                  thisPage.Folds = newFoldArray;
                  MMF.Pages.push(thisPage);

                  if (
                    foldIndex ===
                    currentSameDepthArray[0].depthFoldCount - 1
                  ) {
                    foldIndex = 0;
                  } else {
                    foldIndex++;
                  }
                }

                //empty currentSameDepthArray
                currentSameDepthArray = [];
              }
              //push page to final pattern;
              //If the page only has one fold, have it be unfolded for MMF
              let thisPage = initialPattern.Pages[p]
              if (thisPage.Folds.length === 1) {
                thisPage.Folds[0].depth = 0;
              }
              MMF.Pages.push(initialPattern.Pages[p]);
            }
          }
          //process currentSameDepthArray
          let foldIndex = 0;
          for (var sd2 = 0; sd2 < currentSameDepthArray.length; sd2++) {
            //change the page to only have one depth
            let thisPage = currentSameDepthArray[sd2];
            let newFoldArray = [];

            //find fold we want to keep
            let chosenFold = thisPage.Folds.filter((a) => a.depth === 0)[
              foldIndex
            ];
            let chosenFoldIndex = thisPage.Folds.map(function (e) {
              return e.start;
            }).indexOf(chosenFold.start);

            if (chosenFoldIndex === 0) {
              //If fold is the first, add another fold after it
              newFoldArray.push(chosenFold);
              const nextFold = {
                start: chosenFold.end,
                end: initialPattern.height,
                length: initialPattern.height - chosenFold.end,
                depth: 0,
                color: JIMP_COLORS.WHITE,
              };
              newFoldArray.push(nextFold);
            } else if (chosenFoldIndex === thisPage.Folds.length - 1) {
              //If fold is the last, add another fold before it
              const previousFold = {
                start: 0,
                end: chosenFold.start,
                length: chosenFold.start,
                depth: 0,
                color: JIMP_COLORS.WHITE,
              };
              newFoldArray.push(previousFold);
              newFoldArray.push(chosenFold);
            } else {
              //If fold is in the middle, add a fold on either side of it
              const previousFold = {
                start: 0,
                end: chosenFold.start,
                length: chosenFold.start,
                depth: 0,
                color: JIMP_COLORS.WHITE,
              };
              newFoldArray.push(previousFold);
              newFoldArray.push(chosenFold);
              const nextFold = {
                start: chosenFold.end,
                end: initialPattern.height,
                length: initialPattern.height - chosenFold.end,
                depth: 0,
                color: JIMP_COLORS.WHITE,
              };
              newFoldArray.push(nextFold);
            }

            thisPage.Folds = newFoldArray;
            MMF.Pages.push(thisPage);

            if (foldIndex === currentSameDepthArray[0].depthFoldCount - 1) {
              foldIndex = 0;
            } else {
              foldIndex++;
            }
          }
          resolve(MMF);
        }
      })
      .catch((error) => {
        logEvent(EVENT.CATCH, "GeneratePattern", error, "PatternCreator.js.js");
        reject(error);
      });
  });
};

export default GeneratePattern;
