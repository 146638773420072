import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid";

const Loading = (props) => {
    const {marginTop = 200, showText = true} = props;
    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            style={{marginTop:marginTop}}
            >
            <CircularProgress />
            {showText && <>Loading</>}
        </Grid>
    );
}

export default Loading;