import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from "@material-ui/core/Button";
import { BRAND_COLORS } from '../Utils/Constants';

const LandingPage = () => {
    return (
        <>
            <Container style={{marginTop: "16px"}}>
                <Grid container spacing={2} direction="column" justify="flex-start" alignItems="center">
                    <Grid container direction="row" justify="center">
                        <Grid container item sm={6} direction="column" justify="center" alignItems="center" style={{minHeight: "350px"}}>
                            <Typography variant="h3" component="h1" style={{textAlign: "center"}}>Create your own custom folded book</Typography>
                            <Button style={{margin: "16px", backgroundColor: BRAND_COLORS.SUCCESS, color: BRAND_COLORS.WHITE}} size="large" variant="contained" color="primary" onClick={() => {window.location.href = "/signup";}}>Get Started</Button>
                        </Grid>
                        <Grid item sm={6}><img src={process.env.PUBLIC_URL + '/Combi.png'} alt="Lotus Book Folding" style={{maxWidth: "100%", maxHeight: "100%"}} /></Grid>
                    </Grid>

                    <Grid container direction="row-reverse" justify="center" alignItems="center">
                        <Grid item sm={6} style={{ textAlign: "center", marginTop: "32px", marginBottom: "32px" }}>
                            <Typography variant="h4" style={{fontWeight: "bold"}}>Work on all devices</Typography>
                            <Typography variant="h5">Create and access your patterns from any device with an internet connection. Nothing to download!</Typography>
                        </Grid>
                        <Grid item sm={6} style={{textAlign: "center"}}><img src={process.env.PUBLIC_URL + '/AllDevices.png'} alt="Lotus Book Folding" style={{maxWidth: "100%", maxHeight: "100%"}} /></Grid>
                    </Grid>

                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item sm={6} style={{ textAlign: "center", marginTop: "32px", marginBottom: "32px" }}>
                            <Typography variant="h4" style={{fontWeight: "bold"}}>Supports all major image formats</Typography>
                            <Typography variant="h5">Just upload your image and generate your pattern. No conversion required!</Typography>
                        </Grid>
                        <Grid item sm={6} style={{textAlign: "center"}}><img src={process.env.PUBLIC_URL + '/SupportedImageTypes.png'} alt="Lotus Book Folding" style={{maxWidth: "326px", maxHeight: "375px"}} /></Grid>
                    </Grid>

                    <Grid container direction="row-reverse" justify="center" alignItems="center">
                        <Grid item sm={6} style={{ textAlign: "center", marginTop: "32px", marginBottom: "32px" }}>
                            <Typography variant="h4" style={{fontWeight: "bold"}}>Create error free patterns</Typography>
                            <Typography variant="h5">Lotus Book Folding automatically creates easy to follow and error free patterns.</Typography>
                        </Grid>
                        <Grid item sm={6} style={{textAlign: "center"}}><img src={process.env.PUBLIC_URL + '/PatternViews.png'} alt="Lotus Book Folding" style={{maxWidth: "100%", maxHeight: "100%"}} /></Grid>
                    </Grid>

                    <Grid item sm={6} style={{ textAlign: "center", marginTop: "32px", marginBottom: "32px" }}>
                        <Button style={{margin: "16px", backgroundColor: BRAND_COLORS.SUCCESS, color: BRAND_COLORS.WHITE}} size="large" variant="contained" color="primary" onClick={() => {window.location.href = "/signup";}}>Start Folding</Button>
                    </Grid>
                    <Grid item sm={6}><img src={process.env.PUBLIC_URL + '/LogoTransparent.png'} alt="Lotus Book Folding" style={{maxWidth: "300px", maxHeight: "300px"}} /></Grid>
                    
                </Grid>
            </Container>
        </>
    );
}

export default LandingPage;



