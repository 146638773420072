import React from 'react';
import Container from "@material-ui/core/Container";
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';

const HowToFold = () => {
    return (<>
        <Container>
            <Grid container direction="column" justify="center" alignItems="stretch">

                {/* Materials */}
                <Grid item style={{marginTop:"32px"}}>
                    <Typography variant="h2" component="h1" gutterBottom>Materials</Typography>

                    <Grid container style={{marginTop:"32px"}}>
                        <Grid container item xs={6} md={2} direction="column" justify="flex-start" alignItems="stretch">
                            <Grid item>
                                <Typography variant="h4" style={{textAlign: "center"}} gutterBottom>Book</Typography>
                            </Grid>
                            <Grid item>
                                <img src={process.env.PUBLIC_URL + '/Book.png'} alt="Lotus Book Folding" width={"100%"} />
                            </Grid>
                        </Grid>

                        <Grid container item xs={6} md={2} direction="column" justify="flex-start" alignItems="stretch">
                            <Grid item>
                                <Typography variant="h4" style={{textAlign: "center"}} gutterBottom>Ruler(cm)</Typography>
                            </Grid>
                            <Grid item>
                                <img src={process.env.PUBLIC_URL + '/Ruler.png'} alt="Lotus Book Folding" width={"100%"} />
                            </Grid>
                        </Grid>

                        <Grid container item xs={6} md={2} direction="column" justify="flex-start" alignItems="stretch">
                            <Grid item>
                                <Typography variant="h4" style={{textAlign: "center"}} gutterBottom>Pencil</Typography>
                            </Grid>
                            <Grid item>
                                <img src={process.env.PUBLIC_URL + '/Pencil.png'} alt="Lotus Book Folding" width={"100%"} />
                            </Grid>
                        </Grid>

                        <Grid container item xs={6} md={2} direction="column" justify="flex-start" alignItems="stretch">
                            <Grid item>
                                <Tooltip title="You will not need scissors if you're using the Measure Mark Fold(MMF) method.">
                                    <Typography variant="h4" style={{textAlign: "center"}} gutterBottom>*Scissors</Typography>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <img src={process.env.PUBLIC_URL + '/Scissors.png'} alt="Lotus Book Folding" width={"100%"} />
                            </Grid>
                        </Grid>

                        <Grid container item xs={6} md={2} direction="column" justify="flex-start" alignItems="stretch">
                            <Grid item>
                                <Typography variant="h4" style={{textAlign: "center"}} gutterBottom>Image</Typography>
                            </Grid>
                            <Grid item>
                                <img src={process.env.PUBLIC_URL + '/Image.png'} alt="Lotus Book Folding" width={"100%"} />
                            </Grid>
                        </Grid>

                        <Grid container item xs={6} md={2} direction="column" justify="flex-start" alignItems="stretch">
                            <Grid item>
                                <Typography variant="h4" style={{textAlign: "center"}} gutterBottom>Lotus</Typography>
                            </Grid>
                            <Grid item>
                                <img src={process.env.PUBLIC_URL + '/LogoTransparent.png'} alt="Lotus Book Folding" width={"100%"} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider variant="middle" />
                </Grid>

                {/* Book Info */}
                <Grid item style={{marginTop:"32px"}}>
                    <Typography variant="h2" component="h1">Book Info</Typography>
                    <Typography variant="h5" gutterBottom>Lotus Book Folding needs some information about your book to create a pattern</Typography>

                    <Grid container spacing={3} style={{marginTop:"32px"}}>

                        <Grid container item xs={12} sm={6} direction="column" justify="flex-start" alignItems="stretch">
                            <Grid item>
                                <Typography variant="h4" style={{textAlign: "center"}}>Height</Typography>
                            </Grid>
                            <Grid item>
                                <img src={process.env.PUBLIC_URL + '/MeasureBook.png'} alt="Lotus Book Folding" width={"100%"} />
                            </Grid>
                            <Grid item>
                                <Typography style={{textAlign: "center"}} variant="subtitle1" gutterBottom>Measure in centimeters (cm) the height of a page in your book.</Typography>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} sm={6} direction="column" justify="flex-start" alignItems="stretch">
                            <Grid item>
                                <Typography variant="h4" style={{textAlign: "center"}}>Last odd page</Typography>
                            </Grid>
                            <Grid item>
                                <img src={process.env.PUBLIC_URL + '/BookLastPages.png'} alt="Lotus Book Folding" width={"100%"} />
                            </Grid>
                            <Grid item>
                                <Typography style={{textAlign: "center"}} variant="subtitle1" gutterBottom>The last numbered right hand page of your book.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <Divider variant="middle" />
                </Grid>

                {/* Fold Types */}
                {/* <Grid item  style={{marginTop:"32px"}}>
                    <Typography variant="h2" component="h1">Fold Types</Typography>
                    <Typography variant="h5" gutterBottom>There are 4 methods of book folding</Typography>

                    <Grid container spacing={3} style={{marginTop:"32px"}}>
                        <Grid container item xs={6} sm={3} direction="column" justify="flex-start" alignItems="stretch">
                            <Grid item>
                                <Typography variant="h4" style={{textAlign: "center"}}>MMF</Typography>
                            </Grid>
                            <Grid item>
                                <img src={process.env.PUBLIC_URL + '/LogoBlackTransparent.png'} alt="Lotus Book Folding" width={"100%"} />
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" gutterBottom>Measure Mark Fold: Best for text or simple images. Does not require cutting pages. </Typography>
                            </Grid>
                        </Grid>

                        <Grid container item xs={6} sm={3} direction="column" justify="flex-start" alignItems="stretch">
                            <Grid item>
                                <Typography variant="h4" style={{textAlign: "center"}}>MMCF</Typography>
                            </Grid>
                            <Grid item>
                                <img src={process.env.PUBLIC_URL + '/LogoBlackTransparent.png'} alt="Lotus Book Folding" width={"100%"} />
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" gutterBottom>Measure Mark Cut Fold: Cut and fold each page to create an image</Typography>
                            </Grid>
                        </Grid>

                        <Grid container item xs={6} sm={3} direction="column" justify="flex-start" alignItems="stretch">
                            <Grid item>
                                <Typography variant="h4" style={{textAlign: "center"}}>Combi</Typography>
                            </Grid>
                            <Grid item>
                                <img src={process.env.PUBLIC_URL + '/LogoBlackTransparent.png'} alt="Lotus Book Folding" width={"100%"} />
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" gutterBottom>This is similar to MMCF except the first and last folds on each page are folded at an angle.</Typography>
                            </Grid>
                        </Grid>

                        <Grid container item xs={6} sm={3} direction="column" justify="flex-start" alignItems="stretch">
                            <Grid item>
                                <Typography variant="h4" style={{textAlign: "center"}}>Shadow</Typography>
                            </Grid>
                            <Grid item>
                                <img src={process.env.PUBLIC_URL + '/LogoBlackTransparent.png'} alt="Lotus Book Folding" width={"100%"} />
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1" gutterBottom>Every other page is left unfolded to create a shadow effect. </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <Divider variant="middle" />
                </Grid> */}

                {/* The Pattern */}
                <Grid item  style={{marginTop:"32px"}}>
                    <Typography variant="h2" component="h1">Using the Pattern</Typography>
                    <Typography variant="h5" gutterBottom>After generating a pattern you will see a preview of your finished book along with 3 different pattern formats.</Typography>

                    <Grid container spacing={3} style={{marginTop:"32px"}}>
                        <Grid container item xs={12} alignItems="center">
                            <Grid item xs={12} sm={6}>
                                <Grid item>
                                    <Typography variant="h4" style={{textAlign: "center"}}>Page</Typography>
                                </Grid>
                                <Grid item>
                                    <img src={process.env.PUBLIC_URL + '/MobileView.png'} alt="Lotus Book Folding" width={"100%"} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="subtitle1" gutterBottom>The most beginner-friendly way to fold. The folds are described page by page so you can easily swipe through the pattern while you flip through each page.</Typography>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} alignItems="center">
                            <Grid item xs={12} sm={6}>
                                <Grid item>
                                    <Typography variant="h4" style={{textAlign: "center"}}>Grid</Typography>
                                </Grid>
                                <Grid item>
                                    <img src={process.env.PUBLIC_URL + '/GridView.png'} alt="Lotus Book Folding" width={"100%"} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="subtitle1" gutterBottom>The folds on each page are shown as an individual row on a grid. Just use your ruler to measure and mark each fold.</Typography>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} alignItems="center">
                            <Grid item xs={12} sm={6}>
                                <Grid item>
                                    <Typography variant="h4" style={{textAlign: "center"}}>Print</Typography>
                                </Grid>
                                <Grid item>
                                    <img src={process.env.PUBLIC_URL + '/PrintView.png'} alt="Lotus Book Folding" width={"100%"} />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="subtitle1" gutterBottom>This is the fastest method of folding as you don't need to measure the fold lengths. Print out the pattern on a standard A4 page, match up the pattern to the pages in your book, and trace the fold edges to the page.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    
                    <Divider variant="middle" />
                </Grid>

            </Grid>
        </Container>
    </>);
}

export default HowToFold;