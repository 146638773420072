import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import ReCAPTCHA from "react-google-recaptcha";
//import fetch from 'node-fetch';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import CustomizedSnackbar from "../Components/Snackbar";
import { BRAND_COLORS, features } from '../Utils/Constants';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import firebase from '../Utils/firebase'
import { EVENT, logEvent } from '../Utils/Utils';

function SignUpForm() {
    //const RECAPTCHA_SERVER_KEY = "6Lf8XAgaAAAAAANlrtrEgL5V0Rt9GAGEnvSzhq4p";
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [humanKey, setHumanKey] = useState('');
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [disableSignUp, setDisableSignUp] = useState(true);
    const [snackbar, setSnackbar] = useState(null);
    
    document.body.style = "background: " + BRAND_COLORS.PRIMARY;

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleReCaptchaChange = (value) => {
        setHumanKey(value)
        setCaptchaVerified(false);
        if (value) {
            verifyReCaptchaKey(value);
        }
    }

    const verifyReCaptchaKey = (key) => {
        // const postURL = "https://www.google.com/recaptcha/api/siteverify?secret=" + RECAPTCHA_SERVER_KEY + "&response=" + key;
        // fetch(postURL, {
        //     method: 'post',
        //     //mode: 'no-cors',
        //     mode: 'cors',
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        //     }
        // })
        // .then(function(response) {
        //     console.log(response);
        // }).catch(err => {
        //     console.error(err);
        // });
        setCaptchaVerified(true);
    }
    
    useEffect(() => {
        if (email && password && humanKey && captchaVerified) {
            setDisableSignUp(false);
        } else {
            setDisableSignUp(true);
        }
    }, [email, password, humanKey, captchaVerified]);

    const signUp = () => {
        setDisableSignUp(true);
        firebase.auth().createUserWithEmailAndPassword(email, password)
            .catch(function (error) {
                setSnackbar({
                text: error.message,
                severity: "error",
            });
            logEvent(EVENT.CATCH, "signUp", error, null);
            }).finally(() => {
                setDisableSignUp(false);
        });
    }

    return (
        <>
        <Grid container
            justify="center"
            alignItems="stretch"
            style={{marginTop:"30px"}}
        >
            <Grid container
                direction="column"
                justify="flex-start"
                alignItems="center"
                item 
                xs={10} 
                sm={6} 
                md={4} 
                lg={3}
                style={{backgroundColor: "white"}}>
                    <Grid item><img src={process.env.PUBLIC_URL + '/LogoTransparent.png'} alt="Lotus Book Folding" height={250} /></Grid>
                    <Grid item style={{marginBottom:20}}>
                        <List dense>
                            {features.map((feature) => {
                                return (
                                    <ListItem>
                                        <ListItemIcon>
                                            <CheckCircleIcon style={{color: BRAND_COLORS.SUCCESS}} />
                                        </ListItemIcon>
                                        <ListItemText primary={feature}/>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Grid>   
            </Grid>

            <Grid container
                direction="column"
                justify="center"
                alignItems="center"
                item 
                xs={10} 
                sm={6} 
                md={4} 
                lg={3}
                style={{
                    backgroundColor: "white"}}>
                <Grid item><Typography variant="h4" color="secondary" style={{marginTop:20, marginBottom:20}}>Create Account</Typography></Grid>
                        <Grid item><TextField label="Email" type="email" value={email} onChange={handleEmailChange} style={{width: "194.5px"}}/></Grid>
                        <Grid item>
                            <FormControl style={{width: "194.5px"}}>
                                <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                                <Input
                                    id="standard-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={handlePasswordChange}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item style={{marginTop:10}}><ReCAPTCHA sitekey="6Lf8XAgaAAAAAB0cBA1D2uYGFGxLEIxdwnoBgoEr" onChange={handleReCaptchaChange}/></Grid>
                        <Grid item style={{marginTop:10}}><Button variant="contained" color="secondary" onClick={signUp} disabled={disableSignUp}>Sign up</Button></Grid>
                        <Grid item><Typography variant="h5"></Typography></Grid>
                        <Grid item style={{marginTop:10, marginBottom:20}}><Button variant="outlined" color="secondary" onClick={() => { window.location.href = "/signin";}}>Already have an account?</Button></Grid> 
            </Grid>
            </Grid> 
            {snackbar && (
                <CustomizedSnackbar
                callback={() => {
                    setSnackbar(null);
                }}
                {...snackbar}
                />
            )}
            </>
    );
}

export default SignUpForm;