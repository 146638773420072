import React, {useEffect} from "react";
//import {vectorURLs} from './vectorURLs';

const Test = () => {
  const horseURL = "https://publicdomainvectors.org/en/free-clipart/Black-horse-clip-art/5366.html";
  const otherURL = "https://openclipart.org/detail/335830/simply-worn-out";

    const fetchData = (url = '') => {
        window.fetch(url)
        .then(
          function(response) {
            console.log(response.text());
      
            // Examine the text in the response
            response.json().then(function(data) {
              console.log(data);
            });
          }
        )
        .catch(function(err) {
          console.log('Fetch Error', err);
        });
    }

    async function getData(url = '', data = {}) {
      // Default options are marked with *
      const response = await window.fetch(url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {},
      });
      return response; // parses JSON response into native JavaScript objects
    }

    const iFrame = () => {
      var iframe = document.createElement('iframe');
      iframe.src = horseURL;
      console.log(iframe);
    }

    async function fetchHtmlAsText(url) {
        return await (await fetch(url)).text();
    }

    useEffect(()=>{
      // console.log(fetchHtmlAsText(horseURL));
    }, []);

    return (
        <>Test</>
    );
}

export default Test;