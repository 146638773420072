import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import firebase from "../Utils/firebase";
import ReCAPTCHA from "react-google-recaptcha";
import {
  EVENT,
  logEvent
} from "../Utils/Utils";
import CustomizedSnackbar from "../Components/Snackbar";

const db = firebase.firestore();

const ReportBug = () => {
    const [snackbar, setSnackbar] = useState(null);
    const [sendLoading, setsendLoading] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [humanKey, setHumanKey] = useState('');
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [disableSend, setDisableSend] = useState(true);

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleReCaptchaChange = (value) => {
        setHumanKey(value)
        setCaptchaVerified(false);
        if (value) {
            verifyReCaptchaKey(value);
        }
    }

    const verifyReCaptchaKey = (key) => {
        // const postURL = "https://www.google.com/recaptcha/api/siteverify?secret=" + RECAPTCHA_SERVER_KEY + "&response=" + key;
        // fetch(postURL, {
        //     method: 'post',
        //     //mode: 'no-cors',
        //     mode: 'cors',
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        //     }
        // })
        // .then(function(response) {
        //     console.log(response);
        // }).catch(err => {
        //     console.error(err);
        // });
        setCaptchaVerified(true);
    }

    useEffect(() => {
        if (message && humanKey && captchaVerified && !sendLoading) {
            setDisableSend(false);
        } else {
            setDisableSend(true);
        }
    }, [message, humanKey, captchaVerified, sendLoading]);

    const uploadDocument = async () => {
        return new Promise((resolve, reject) => {
            var user = firebase.auth().currentUser;
            const data = {
                uid: user.uid,
                email: user.email,
                message: message,
                dateTime: Date.now()
            };
            const bugCollection = db.collection("bugs");
            const docRef = bugCollection.doc();
            docRef
            .set(data, { merge: true })
            .then(() => {
                //Save Successful
                resolve(docRef.id);
            })
            .catch((error) => {
                logEvent(EVENT.CATCH, "uploadDocument", error, data);
                reject(error);
            });
        });
    }

    const sendBug = () => {
        setsendLoading(true);

        uploadDocument().then(() => {
            setSnackbar({
                text: "Request received, thank you!",
                severity: "success",
            });
            window.location.href = "/";
        }).catch((error) => {
            setSnackbar({
                text: "Error sending request",
                severity: "error",
            });
        }).finally(() => {
            setsendLoading(false);
        });
    }

    return (
        <>
            {snackbar && (
                <CustomizedSnackbar
                callback={() => {
                    setSnackbar(null);
                }}
                {...snackbar}
                />
            )}
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography variant="h2" color="secondary">
                        Request a feature
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Describe the functionality you are requesting"
                        multiline
                        rows={4}
                        variant="outlined"
                        value={message}
                        onChange={handleMessageChange}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}><ReCAPTCHA sitekey="6Lf8XAgaAAAAAB0cBA1D2uYGFGxLEIxdwnoBgoEr" onChange={handleReCaptchaChange} /></Grid>

                <Grid item xs={12}>
                    <Button
                        variant="outlined"
                        size="medium"
                        color="primary"
                        disabled={disableSend}
                        onClick={() => { sendBug() }}
                    >
                        Send
                    </Button>
                </Grid>
                
            </Grid>
        </>
    );
}

export default ReportBug;