import firebase from "./firebase";
import {
  getRandomName,
  getImageNameWithExtensionFromURL,
  EVENT,
  logEvent
} from "../Utils/Utils";
const db = firebase.firestore();
const storageRef = firebase.storage().ref();

export async function uploadImage(imageURL) {
  var user = firebase.auth().currentUser;

  return new Promise((resolve, reject) => {
    var storageRef = firebase.storage().ref();

    if (!imageURL) {
      resolve("");
    } else {
      var imageUploadTask = storageRef
        .child("users/" + user.uid + "/" + getRandomName() + ".jpg")
        .putString(imageURL, "data_url");

      imageUploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function (snapshot) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
              console.log("Upload is paused");
              break;
            case firebase.storage.TaskState.RUNNING:
              console.log("Upload is running");
              break;
            default:
              break;
          }
        },
        function (error) {
          //TODO
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;
            case "storage/unknown":
              // Unknown error occurred, inspect error.serverResponse
              break;
            default:
              break;
          }
          logEvent(EVENT.CATCH, "uploadImage", error, error.code);
          reject(error);
        },
        function () {
          // Upload completed successfully, now we can get the download URL
          imageUploadTask.snapshot.ref
            .getDownloadURL()
            .then(function (downloadURL) {
              resolve(downloadURL);
            })
            .catch((error) => {
              logEvent(EVENT.CATCH, "uploadImage", error, null);
            });
        }
      );
    }
  });
}

export async function deleteImage(imageURL) {
  var user = firebase.auth().currentUser;
  return new Promise((resolve, reject) => {
    var imageToDeleteRef = storageRef.child(
      "users/" + user.uid + "/" + getImageNameWithExtensionFromURL(imageURL)
    );
    imageToDeleteRef
      .delete()
      .then(function () {
        resolve();
      })
      .catch(function (error) {
        logEvent(EVENT.CATCH, "deleteImage", error, null);
        reject(error);
      });
  });
}

export async function uploadDocument(book) {
  var user = firebase.auth().currentUser;
  return new Promise((resolve, reject) => {
    const data = {
      height: book.height,
      name: book.name,
      imageURL: book.url,
      modified: new Date(),
      imageType: book.imageType,
      foldType: book.foldType,
      lastOddPage: book.lastOddPage,
      text: book.text,
      textFont: book.textFont,
      textSize: book.textSize,
    };
    const bookCollection = db
      .collection("users")
      .doc(user.uid)
      .collection("books");
    const docRef = book.id ? bookCollection.doc(book.id) : bookCollection.doc();
    docRef
      .set(data, { merge: true })
      .then(() => {
        //Save Successful
        resolve(docRef.id);
      })
      .catch((error) => {
        logEvent(EVENT.CATCH, "uploadDocument", error, data);
        reject(error);
      });
  });
}

export async function saveBook(initialImageURL, currentBook) {
  return new Promise((resolve, reject) => {
    //Update Book
    if (initialImageURL) {
      //Update Image
      if (initialImageURL !== currentBook.url) {
        //delete old image
        if (initialImageURL) {
          deleteImage(initialImageURL)
            .then(() => {
              console.log("Image Deleted");
            })
            .catch((error) => {
              logEvent(EVENT.CATCH, "saveBook", error, {"initialImageURL": initialImageURL});
              console.log("Error deleting image");
            });
        }
        //Upload New Image
        uploadImage(currentBook.url).then((downloadURL) => {
          currentBook.url = downloadURL;
          uploadDocument(currentBook)
            .then((id) => {
              currentBook.id = id;
              resolve(currentBook);
            })
            .catch((error) => {
              logEvent(EVENT.CATCH, "saveBook", error, {"currentBook": currentBook});
              reject(error);
            });
        });
      } else {
        //Update Document
        uploadDocument(currentBook)
          .then((id) => {
            currentBook.id = id;
            resolve(currentBook);
          })
          .catch((error) => {
            logEvent(EVENT.CATCH, "saveBook", error, {"currentBook": currentBook});
            reject(error);
          });
      }
    } else {
      //Create New Book
      uploadImage(currentBook.url).then((downloadURL) => {
        currentBook.url = downloadURL;
        uploadDocument(currentBook)
          .then((id) => {
            currentBook.id = id;
            resolve(currentBook);
          })
          .catch((error) => {
            logEvent(EVENT.CATCH, "saveBook", error, {"currentBook": currentBook});
            reject(error);
          });
      });
    }
  });
}

export async function deleteBook(book) {
  var user = firebase.auth().currentUser;

  return new Promise((resolve, reject) => {
    db.collection("users")
      .doc(user.uid)
      .collection("books")
      .doc(book.id)
      .delete()
      .then(() => {
        //Delete Storage Image
        if (book.url) {
          var imageRef = storageRef.child(
            "users/" +
              user.uid +
              "/" +
              getImageNameWithExtensionFromURL(book.url)
          );
          imageRef
            .delete()
            .then(() => {
              console.log("Deleted image");
            })
            .catch(function (error) {
              logEvent(EVENT.CATCH, "deleteBook", error, null);
            });
        }
        resolve();
      })
      .catch(function (error) {
        logEvent(EVENT.CATCH, "deleteBook", error, null);
        reject(error);
      });
  });
}
