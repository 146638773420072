import React, { useState, useEffect } from "react";
import firebase from "../Utils/firebase";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Hidden from '@material-ui/core/Hidden';
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { getHeightCM } from "../Utils/Utils";
import Loading from '../Components/Loading';
import EmptyBookshelf from '../Components/EmptyBookshelf'

function BookShelf() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const fetchData = () => {
    var db = firebase.firestore();
    var user = firebase.auth().currentUser;
    async function getBooks() {
      const books = db.collection("users").doc(user.uid).collection("books");
      const snapshotRef = await books.get();
      setLoading(false);
      if (snapshotRef.empty) {
        setData(null);
      } else {
        setData(snapshotRef.docs);
      }
    }
    getBooks();
  };

  const showBooksSm = () => {
    return data ? (
      <GridList cellHeight={180} cols={3}>
        {data.map((doc) => (
          <GridListTile
          key={doc.id}
            onClick={() => {
              window.location.href = "/book?id=" + doc.id;
            }}
          >
            {doc.data().imageURL ? (
              <img style={{width:"100%"}} src={doc.data().imageURL} alt={doc.data().name} />
            ) : (
              <img style={{width:"100%"}} src={process.env.PUBLIC_URL + '/LogoTransparent.png'} alt={doc.data().name} />
            )}
            <GridListTileBar
              title={doc.data().name}
              subtitle={
                <span>
                  {doc.data().pages} pages, {getHeightCM(doc.data().height)}cm
                </span>
              }
            />
          </GridListTile>
        ))
      }
      </GridList>
    ) : (<EmptyBookshelf />) 
  }

  const showBooksLg = () => {
    return data ? (
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Fold Type</TableCell>
              <TableCell align="right">Height (cm)</TableCell>
              <TableCell align="right">Last odd page</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data ? data.map((doc) => (
              <TableRow
                hover
                key={doc.id}
                onClick={() => {
                  window.location.href = "/book?id=" + doc.id;
                }}>
                <TableCell>
                  {doc.data().imageURL ? (
                    <img height="100" src={doc.data().imageURL} alt={doc.data().name} />
                  ) : (
                      <MenuBookIcon
                        color="disabled"
                        style={{ fontSize: 100, opacity: 0.5 }}
                      />
                    )}
                </TableCell>
                <TableCell component="th" scope="row">
                  {doc.data().name}
                </TableCell>
                <TableCell>{doc.data().foldType}</TableCell>
                <TableCell align="right">{getHeightCM(doc.data().height)}</TableCell>
                <TableCell align="right">{doc.data().lastOddPage}</TableCell>
              </TableRow>
            )) : ""}
          </TableBody>
        </Table>
      </TableContainer>
    ) : (<EmptyBookshelf />) ;
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    loading ? <Loading /> :
      <>
        <Hidden mdUp>
          <Box className={classes.root}>
            {showBooksSm()}
          </Box>
        </Hidden>
        <Hidden smDown>
          {showBooksLg()}
        </Hidden>
      </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default BookShelf;
