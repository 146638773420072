import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const StyledCanvas = styled.canvas`
  display: none;
`;

const StyledImg = styled.img`
  border: 1px solid #d3d3d3;
  maxWidth: 300px;
  maxHeight: 300px;
`;

const TextPreview = (props) => {
  const canvasRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");

  const {text, height, lastOddPage, size, callBack, font} = props;
  const width = (lastOddPage + 1) / 2;

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    //Clear canvas
    context.clearRect(0, 0, width, height);

    //Set Text Size and font
    context.font = size + "px " + font;

    //Measure text to determine left/top margins for centering
    const textMeasurements = context.measureText(text);
    const textWidth = textMeasurements.width;
    const textHeight = textMeasurements.actualBoundingBoxAscent + textMeasurements.actualBoundingBoxDescent;
    const leftMargin = (width - textWidth) / 2;
    const topMargin = textHeight - 5 + (height - textHeight) / 2;

    //Write text to canvas
    context.fillText(text, leftMargin, topMargin);

    //Get data URL of canvas
    const dataURL = context.canvas.toDataURL();
    setImgSrc(dataURL);

    //Callback
    callBack(dataURL);
  }, [text, font, height, callBack, size, width]);

  return (
    <>
      <StyledCanvas
        ref={canvasRef}
        width={width}
        height={height}
        {...props}
      />
      <StyledImg alt="" src={imgSrc} />
    </>
  );
};

export default TextPreview;
