import React, { useEffect, useRef, useState } from "react";
import Jimp from "jimp";
import { EVENT, logEvent } from '../Utils/Utils';
import Loading from './Loading';

function Canvas(props) {
  const [loading, setLoading] = useState(true);
  const canvas = useRef(null);
  const size = 250;
  const { imageURL } = props;

  useEffect(() => {
    const context = canvas.current.getContext("2d");
    readImage(imageURL).then((img)=>{
      drawImage(img, context);
      setLoading(false);
    })
  }, [imageURL]);

  const drawImage = (image, context) => {
    context.clearRect(0, 0, size, size);
    var img = new Image();
    img.onload = function () {
      context.drawImage(img, 0, 0);
    };
    img.src = image;
  };
  
  const readImage = (imageURL) => {
    return new Promise((resolve, reject) => {
      Jimp.read(imageURL)
        .then((image) => {
          let imageWidth;
          let imageHeight;
  
          if (image.bitmap.width > image.bitmap.height) {
            imageWidth = size;
            imageHeight = Jimp.AUTO;
          } else if (image.bitmap.height > image.bitmap.width) {
            imageWidth = Jimp.AUTO;
            imageHeight = size;
          } else {
            imageWidth = size;
            imageHeight = size;
          }
          image
            .resize(imageWidth, imageHeight, Jimp.RESIZE_NEAREST_NEIGHBOR)
            .getBase64(Jimp.AUTO, (err, src) => {
              resolve(src);
            });
        })
        .catch((error) => {
          logEvent(EVENT.CATCH, "useEffect", error, "Canvas.js");
          reject(error);
        });
    });
  }

  return (
    <>
      {loading && <Loading marginTop={75} showText={false}/>}
      <canvas ref={canvas} width={loading ? 1 : size} height={loading ? 1 : size} />
    </>);
}

export default Canvas;
