import React from 'react'
import Typography from '@material-ui/core/Typography';

function EmptyBookshelf() {
    return (
        <>
            <Typography variant="h5" style={{width: "100%", textAlign: "center"}}>
                Your Bookshelf is empty!
            </Typography>
            <Typography variant="h6" style={{width: "100%", textAlign: "center", fontSize: "16px"}}>
                Click 'Create Book' to begin
            </Typography>
        </>
    )
}

export default EmptyBookshelf;